import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ActivatedRoute, NavigationEnd, Params, Router} from '@angular/router';
import {mergeMap, takeUntil, tap} from 'rxjs/operators';
import {IStreamModel, MediaOverviewData} from '@src/app/models/stream.model';
import {IUserPublisher} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {IMediaItem} from '@src/app/models/core.model';
import {IListFilterItem, IListQuery, LOAD_TYPE} from '@src/app/components/media-list/media-list.component';
import {AuthService} from '@src/app/services/auth/auth.service';
import {LoadingService} from '@src/app/services/loading/loading.service';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import {PlayerControlsService} from '@src/app/services/player-controls/player-controls.service';
import {IPagedResponse, IPagedResponseResults} from '@src/app/models/response.model';
import {
    FILTER_MEDIA_TYPES,
    FILTER_TYPES,
    IMediaListsConfig
} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {BehaviorSubject, forkJoin, Observable, of} from 'rxjs';
import {
    PublicStreamPageService,
    SUGGESTED_VIDEO_TYPE
} from '@src/app/services/public-stream-page/public-stream-page.service';
import {ChatState} from '@src/app/state/chat-state';
import {MEDIA_FILTERS_FIELDS, MediaListService} from '@src/app/services/media-list/media-list.service';
import {STREAM_STATUS} from '@src/app/components/streamdust-player/constants/status';
import {ERRORS_KEYS} from '@src/app/constants/errors-keys';
import {IInfoPanel, InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';
import {DonationState} from '@src/app/state/donation-state';
import {ChatService} from '@src/app/services/chat/chat.service';
import {CONTENT_RESTRICTION} from '@src/app/constants/content-restrictions';
import {UserService} from '@src/app/services/user/user.service';
import {ITimeRangeConfig, LocalizationProvider} from 'ui-elements';
import {IMediaGroup} from '@src/app/models/stream-groups.model';
import {StreamWorldMapState} from '@src/app/state/stream-world-map';
import {environment} from '@src/environments/environment';
import {scrollToTop} from '@src/app/utils/scroll.util';
import {EventSiteState} from '@src/app/state/event-site-state';
import { IPresentationList, PresentationService } from '@src/app/services/file/presentation.service';

@Component({
    selector: 'app-public-stream-page',
    templateUrl: './public-media-page.component.html',
    styleUrls: ['./public-media-page.component.sass'],
    providers: [AutoDestroyService, ChatState, DonationState, ChatService],
})
export class PublicMediaPageComponent implements OnInit, OnDestroy {
    public PLAYER_MODE = PLAYER_MODE;
    @Input() public loadedFromSubdomain: boolean;
    @Input() public mediaId: string;
    @Input() public mediaType: MEDIA_TYPE;
    public data: MediaOverviewData;
    public hasAccess: boolean = false;
    public suggestedMedia: ISuggestedMedia[] = [];
    public mediaGroupsMedia: ISuggestedMedia[] = [];
    public publisher: IUserPublisher;
    public FILTER_TYPE = FILTER_TYPES;
    public MEDIA_FILTERS_FIELDS = MEDIA_FILTERS_FIELDS;
    public LOAD_TYPE = LOAD_TYPE;
    public SUGGESTED_VIDEO_TYPE = SUGGESTED_VIDEO_TYPE;
    public restrictedByCountry: boolean;
    public activeGroupIndex: number = 0;
    public coverImage: string;
    public environment = environment;
    public eventSite: boolean;
    public loading = true;
    public presentations: IPresentationList[] = [];

    suggestedMediaListsConfig: IMediaListsConfig = {
        filters: {
            filterTypes: [this.FILTER_TYPE.SORT, this.FILTER_TYPE.GENRE,
                this.FILTER_TYPE.CONTENT_ACCESS_TYPE, this.FILTER_TYPE.PUBLISHER_GROUP],
            mediaTypes: []
        },
        withSearch: true,
        filtration: true,
        filtersCssClass: 'blue-theme-filter',
        cssClass: 'card-profile title-level-1',
        cardBackgroundCssClass: 'bg-light',
        skeletonBackgroundClass: 'bg-lighter',
        mediaCardElements: {
            group: true
        }
    };

    mediaGroupQuery: IListQuery = {
        paging: {
            page: 0,
            itemsOnPage: 4
        },
        sort: [
            {
                order: 'DESC',
                field: 'DATE'
            }
        ],
        filters: []
    };

    contentIsOnReview = false;

    publisherInfoPanel: IInfoPanel = InfoPanelsConfig.publisherUnapprovedContentInfo;

    constructor(
        public streamService: StreamService,
        private readonly destroy$: AutoDestroyService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly router: Router,
        public authService: AuthService,
        public loadingService: LoadingService,
        public playerControlsService: PlayerControlsService,
        private publicStreamPageService: PublicStreamPageService,
        private mediaListService: MediaListService,
        private userService: UserService,
        private localizationProvider: LocalizationProvider,
        public worldMapState: StreamWorldMapState,
        public eventSiteState: EventSiteState,
        public presentationService: PresentationService
    ) {
    }

    ngOnInit(): void {
        this.loadData();
        this.loadPresentations();
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((e: any) => {
            if (e instanceof NavigationEnd) {
                // window.scrollTo({top: 0, behavior: 'smooth'});
                this.loadData();
            }
        });
    }

    loadData() {
        if (!this.loadedFromSubdomain) {
            this.mediaType = this.resolveMediaType();
        }
        this.activatedRoute.params.pipe(
            tap(({id}: Params) => {
                if (!this.loadedFromSubdomain || id) {
                    this.mediaId = id;
                }
                this.suggestedMediaListsConfig.filters.mediaId = this.mediaId;
            }),
            mergeMap(() => this.streamService.getStreamLandingInfo(this.mediaType, this.mediaId)),
            tap((response) => {
                if (!response.success) {
                    if (response.errorKey === ERRORS_KEYS.CONTENT_IS_ON_REVIEW) {
                        this.contentIsOnReview = true;
                    }
                    this.loading = false;
                    return;
                }
                this.streamService.getPublicLandingPageInfo(this.mediaType, this.mediaId)
                    .pipe(takeUntil(this.destroy$))
                    .subscribe((res) => {
                        this.coverImage = this.environment.backendApiHost + res;
                        scrollToTop();
                    }, () => this.loading = false);
                this.streamService.connectStreamStatusWebsocket(this.mediaId);
                this.data = response?.results?.data;
                this.userService.setPublisherHeaderPicture(this.data?.publisher?.headerPicture, true);
                this.suggestedMedia = this.getSuggestedMedia().filter(suggestedMedia => suggestedMedia.originalLength);
                this.authService.isAuthorized$.pipe(takeUntil(this.destroy$))
                    .subscribe(isAuthorized => {
                        if (!isAuthorized) {
                            this.userService.setPublisherHeaderPicture(this.data?.publisher?.headerPicture, true);
                        }
                    });
            }),
            mergeMap(() => {
                if (this.data?.video?.groups?.length) {
                    this.mediaGroupsMedia = this.data?.video?.groups.map(group => this.getMediaGroupConfig(group));
                    const mediaGroupQueries = this.mediaGroupsMedia.map(mediaGroup => {
                        const query = JSON.parse(JSON.stringify(this.mediaGroupQuery));
                        query.filters = mediaGroup.implicitFilters;
                        return query;
                    });
                    return forkJoin(mediaGroupQueries.map(query => this.mediaListService.getMediaList(query)));
                } else {
                    return of(null);
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe((response: IPagedResponse<IMediaItem>[]) => {
            if (response) {
                this.mediaGroupsMedia.forEach((mediaGroup, index) => {
                    mediaGroup.media = response[index].results.data;
                });
            }
            this.checkForRestriction();
            this.loading = false;
        });
    }

    loadPresentations() {
        this.presentationService.getPresentationByMediaIDV2(this.mediaId).subscribe((presentations) => {
            if (presentations.success) {
                this.presentations = presentations.results.data || [];
            }
        });
    }

    private checkForRestriction(): void {
        this.restrictedByCountry = this?.data?.restrictions.includes(CONTENT_RESTRICTION.RESTRICTED_BY_COUNTRY);
    }

    private resolveMediaType(): MEDIA_TYPE {
        return (this.activatedRoute.data as BehaviorSubject<any>)?.value?.mediaType;
    }

    getSuggestedMedia(): ISuggestedMedia[] {
        return [
            {
                title: 'stream.public-page.actual-streams',
                media: this.data.actualStreams,
                implicitFilters: [
                    {
                        field: MEDIA_FILTERS_FIELDS.EXCLUDE_ID,
                        value: this.mediaId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: this.data?.video?.owner
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.STREAM
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                        value: STREAM_STATUS.SCHEDULED
                    }
                ],
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
                originalLength: this.data?.actualStreams?.items?.length
            },
            {
                title: 'stream.public-page.future-streams',
                media: this.data.futureStreams,
                implicitFilters: [
                    {
                        field: MEDIA_FILTERS_FIELDS.EXCLUDE_ID,
                        value: this.mediaId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: this.data?.video?.owner
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: FILTER_MEDIA_TYPES.STREAM
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                        value: STREAM_STATUS.SCHEDULED
                    }
                ],
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
                originalLength: this.data?.futureStreams?.items?.length
            },
            {
                title: 'stream.public-page.past-streams',
                media: this.data.pastVideos,
                implicitFilters: [
                    {
                        field: MEDIA_FILTERS_FIELDS.EXCLUDE_ID,
                        value: this.mediaId
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.PUBLISHER,
                        value: this.data?.video?.owner
                    },
                    {
                        field: MEDIA_FILTERS_FIELDS.MEDIA_TYPE,
                        value: [FILTER_MEDIA_TYPES.STREAM_RECORDING, FILTER_MEDIA_TYPES.VIDEO_ON_DEMAND]
                    }
                ],
                originalLength: this.data?.pastVideos?.items?.length,
                thumbnailGrayScale: true,
                fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters)
            }
        ];
    }

    getMediaGroupConfig(group: IMediaGroup): ISuggestedMedia {
        return {
            title: this.localizationProvider.getByKey('public-media-page.media-group-media.title') + ' '
                + (group.name),
            media: this.data.actualStreams,
            implicitFilters: [
                {
                    field: MEDIA_FILTERS_FIELDS.GROUP_ID,
                    value: group.id
                },
                {
                    field: MEDIA_FILTERS_FIELDS.STREAM_STATUS,
                    value: [
                        STREAM_STATUS.SCHEDULED,
                        STREAM_STATUS.ACTIVATION,
                        STREAM_STATUS.ACTIVATED,
                        STREAM_STATUS.CONNECTED,
                        STREAM_STATUS.PAUSED,
                        STREAM_STATUS.LIVE,
                        STREAM_STATUS.FINISHED,
                    ],
                },
            ],
            loadMoreCustomClick: () => {
                this.router.navigate(['groups', group.id, 'details']);
            },
            fetchMethod: (filters: IListQuery) => this.mediaListService.getMediaList(filters),
            originalLength: this.data?.actualStreams?.items?.length
        };
    }

    changeActiveGroup(index: number): void {
        this.activeGroupIndex = index;
    }

    public grantAccess(): void {
        this.hasAccess = true;
    }

    ngOnDestroy(): void {
        this.eventSiteState.isEventSite$.pipe(takeUntil(this.destroy$))
            .subscribe((isEventSite) => {
                if (isEventSite) {
                    return;
                }
                this.userService.clearPublisherHeader();
                this.authService.isAuthorized$.pipe(takeUntil(this.destroy$))
                    .subscribe((authorized) => {
                        if (authorized) {
                            this.userService.refreshHeaderPicture();
                            return;
                        }
                    });
            });
    }
}

export interface ISuggestedMedia {
    title?: string;
    groupView?: boolean;
    media?: IPagedResponseResults<IMediaItem | IStreamModel>,
    activeQuery?: IListQuery;
    originalLength?: number;
    thumbnailGrayScale?: boolean;
    filtration?: boolean;
    filters?: {
        filterTypes: FILTER_TYPES[],
        mediaTypes: FILTER_MEDIA_TYPES[]
    };
    timeRangeConfig?: ITimeRangeConfig;
    fetchMethod?: (filters: IListQuery) => Observable<IPagedResponse<IMediaItem>>;
    mediaType?: MEDIA_TYPE;
    implicitFilters?: IListFilterItem[];
    loadMoreCustomClick?: () => void;
    mediaItemCustomClick?: (arg: any) => void;
    showAddStreamButton?: boolean;
}

export interface ISuggestedMediaFilters {
    filterTypes: FILTER_TYPES[];
    mediaTypes: FILTER_MEDIA_TYPES[];
    mediaId?: string;
}
