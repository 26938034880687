<div class="public-media-page-wrapper">
  <section *ngIf="loading && (eventSiteState.isEventSite$ | async)" class="section loading-container">
    <ano-loader [isOpen]="(loadingService.loading | async) && (eventSiteState.isEventSite$ | async)"></ano-loader>
  </section>

  <div
    [class.event-site]="loadedFromSubdomain || (eventSiteState.isEventSite$ | async)"
    [ngStyle]="
      loadedFromSubdomain || (eventSiteState.isEventSite$ | async)
        ? {
            'background-image': 'url(' + coverImage + ')',
            'background-size': 'cover',
            'background-position': 'center top',
            'background-repeat': 'no-repeat'
          }
        : {}
    "
  >
    <section class="section section-live-streams mt-0">
      <div class="box-live-streams">
        <div class="container media-player-container" [class.content-center]="restrictedByCountry">
          <ng-container *ngIf="!loading">
            <h1 *ngIf="contentIsOnReview">{{ 'public-media-page.content-is-on-review-text' | localize }}</h1>
            <h1 *ngIf="restrictedByCountry">
              {{ 'public-media-page.content-is-on-restricted_by_country' | localize }}
            </h1>

            <div
              class="box-streams"
              [class.mb-0]="data?.video?.groups?.length > 0"
              *ngIf="!contentIsOnReview && !restrictedByCountry"
            >
              <div class="row">
                <div class="col-lg-12">
                  <app-streamdust-player
                    *ngIf="!loading"
                    [mediaType]="mediaType"
                    [mediaId]="mediaId"
                    [mode]="PLAYER_MODE.VIEWER"
                    (grantAccess$)="grantAccess()"
                  ></app-streamdust-player>
                  <div class="player-footer">
                    <div class="row">
                      <div class="col-lg-8">
                        <h2 class="title stream-title">{{ data?.video?.title }}</h2>
                        <div class="stream-description">
                          <ng-container *ngFor="let descriptionLine of data?.video?.description.split('\n')">
                            <p class="description-line">{{ descriptionLine }}</p>
                          </ng-container>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <app-publisher-info
                          [publisher]="data?.publisher"
                          [contacts]="data?.contacts"
                          [data]="data"
                        ></app-publisher-info>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </section>

    <div *ngIf="presentations.length > 0" class="container public-page-presentations">
      <h2 class="presentation-list-title mb-20">
        <b>{{ 'public-media-page.presentation-list-title' | localize }}</b>
      </h2>
      <div class="row">
        <div class="col-lg-12">
          <app-presentation-list [presentations]="presentations"></app-presentation-list>
        </div>
      </div> 
    </div>

    <div class="container" [class.event-site-devider]="loadedFromSubdomain || (eventSiteState.isEventSite$ | async)">
      <div class="horizontal-divider mt-35"></div>
    </div>

    <div *ngIf="data" class="container public-page-suggested-media mb-40">
      <div class="box-live-streams">
      <app-media-lists
          [mediaLists]="suggestedMedia"
          [config]="suggestedMediaListsConfig"
          [isPlaceholder]="true"
        ></app-media-lists>
      </div>
    </div>
    <app-stream-world-map [id]="2" [streamId]="mediaId"></app-stream-world-map>
  </div>
</div>
