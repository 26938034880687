import { Component, OnDestroy } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { IdwLoginService } from '@src/app/services/idwlogin/idwlogin.service'

@Component({
  selector: 'app-idwlogin-page',
  templateUrl: './idwlogin-page.component.html',
  styleUrls: ['./idwlogin-page.component.scss'],
})
export class IdwLoginPageComponent implements OnDestroy {
  public loginForm: FormGroup
  public isLoading = false
  public errorMessage: string = null

  private destroy$ = new Subject<void>()

  constructor(private formBuilder: FormBuilder, private idwLoginService: IdwLoginService, private router: Router) {
    this.initForm()
  }

  public ngOnDestroy(): void {
    this.destroy$.next()
    this.destroy$.complete()
  }

  public onSubmit(): void {
    if (this.loginForm.valid) {
      this.isLoading = true
      this.errorMessage = null

      this.idwLoginService
        .login(this.loginForm.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res) => {
            if (res?.success) {
              location.reload()
            } else {
              this.isLoading = false
              this.errorMessage = res?.message || 'Login failed. Please try again.'
            }
          },
          error: (error) => {
            this.isLoading = false
            this.errorMessage = error?.error?.message || 'Login failed. Please try again.'
          },
        })
    }
  }

  private initForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    })

    this.loginForm.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.errorMessage = null
    })
  }
}
