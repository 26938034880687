import {EventEmitter, Injectable} from '@angular/core';
import {Observable, of, Subject, throwError} from 'rxjs';
import {ApiService} from 'ui-elements';
import {API_URLS_APP, UrlGenerator} from '@src/app/constants/api-urls.constant';
import {IPagedResponse, IResponse, PagingParams, SortParam} from '@src/app/models/response.model';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';

export interface IPresentationList {
    mediaId: string;
    id: string;
    title: string;
    link: string;
    fileId: string;
    slides: [];
    active: string;
    viewerDownloadAllowed: boolean;
    fileName: boolean;
    uploadTime: number;
    downloads: boolean;
    calls: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class PresentationService {
    toggleTableStatus$: EventEmitter<any> = new EventEmitter<any>();


    public constructor(
        private apiService: ApiService,
        private http: HttpClient,
    ) {
    }

    public uploadPresentation(file: File): Observable<IFileUploadingResponse> {
        const formData = new FormData();
        formData.append('files', file);

        return this.apiService.post(API_URLS_APP.UPLOAD_PRESENTATION,
            formData,
            // {isWithoutRootUrl: true}
        );
    }

    public uploadPresentationV2(file: File): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append('files', file);

        const req = new HttpRequest('POST', '/api/v1/' + API_URLS_APP.UPLOAD_PRESENTATION, formData, {
            reportProgress: true,
            responseType: 'json'
        });

        return this.http.request(req); // idk why reportProgress flag with apiService is not working
    }

    public save(body: IPresentationRequest, isMocked: boolean = false): Observable<IResponse> {
        return this.apiService.post(API_URLS_APP.PRESENTATION_CREATE, body);
    }

    public active(body: IPresentationActiveRequest): Observable<IResponse> {
        return this.apiService.put(API_URLS_APP.PRESENTATION_ACTIVE, body);
    }

    public clickLink(presentationId): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.PRESENTATION_CLICK_LINK, {presentationId}), null, {isWithoutRootUrl: true});
    }

    public clickShow(presentationId): Observable<IResponse> {
        return this.apiService.put(UrlGenerator.generate(API_URLS_APP.PRESENTATION_CLICK_SHOW, {presentationId}), null, {isWithoutRootUrl: true});
    }

    getPresentationByMediaID(mediaId) {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PRESENTATION_BY_MEDIAID, {mediaId}));
    }

    getPresentationByMediaIDV2(mediaId): Observable<IResponse<{data: IPresentationList[]}>> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PRESENTATION_BY_MEDIAID_V2, {mediaId}));
    }

    public deletePresentation(presentationId: string): Observable<IResponse> {
        return this.apiService.delete(UrlGenerator.generate(API_URLS_APP.PRESENTATION_DELETE, {presentationId}));
    }

    public getList(mediaId: string,
                   searchQuery = '',
                   paging: PagingParams = DEFAULT_PAGING,
                   sort: SortParam[] = DEFAULT_SORTING,
                   isMocked: boolean = false
    ): Observable<IPagedResponse<IPresentationList>> {
        return this.apiService.post(UrlGenerator.generate(API_URLS_APP.GET_PRESENTATION_LIST, {mediaId}), {
            paging,
            sort,
            searchTerm: searchQuery
        });
    }

    public downloadPresentation(presentationId: number): Observable<any> {
        return this.apiService.get(UrlGenerator.generate(API_URLS_APP.PRESENTATION_DOWNLOAD, {presentationId}),
            {responseType: 'blob', observe: 'response', isWithoutRootUrl: true});
    }
}

export interface IFileUploadingResponse {
    id: string;
}

export interface IPresentationRequest {
    id?: string;
    mediaId: string;
    title: string;
    link: string;
    fileId: string;
    viewerDownloadAllowed: boolean;
}

export interface IPresentationActiveRequest {
    presentationId?: string;
    active: boolean;
}

const DEFAULT_PAGING = {
    page: 0,
    itemsOnPage: 10
} as PagingParams;

const DEFAULT_SORTING = [{
    field: 'FILE_NAME',
    order: 'ASC'
}] as SortParam[];
