import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  commonDeclarations,
  commonExports,
  commonImports,
  routes
} from './idwlogin-page.common';
import { SharedModule } from '@src/app/modules/shared/shared.module';

@NgModule({
  declarations: [
    ...commonDeclarations,
  ],
  imports: [
    ...commonImports,
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes)
  ],
  exports: [
    ...commonExports,
  ],
})
export class IdwLoginPageModule { }
