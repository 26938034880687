import { Component, Input } from '@angular/core'
import { IPresentationList } from '@src/app/services/file/presentation.service'

@Component({
  selector: 'app-presentation-list',
  templateUrl: './presentation-list.component.html',
  styleUrls: ['./presentation-list.component.scss'],
})
export class PresentationListComponent {
  @Input() presentations: IPresentationList[]

  constructor() {}
}
