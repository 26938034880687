<app-resizable-element #resizableElement
                       *ngIf="(presentationState.activeState$ | async) || isDialog"
                       [title]="config.presentation.title"
                       [draggable]="true"
                       [resizable]="mode !== CHAT_MODE.EMBEDDED"
                       [fullScreenClass]="fullscreenChat ? 'fullscreen-chat' : ''"
                       [embeddedClass]="mode === CHAT_MODE.OVERLAY || mode === CHAT_MODE.EMBEDDED ? 'presentation-embedded' : ''"
                       [fullscreen]="fullscreenButton"
                       [dragPosition]="dragPosition"
                       [footerHidden]="true"
                       [embedded]="embeddedButton"
                       [minHeight]="377"
                       [minWidth]="565"
                       [close]="closeButton"
                       [cssClass]="'presentation'"
                       (resize)="resize($event)"
>
    <ng-container role="body">
        <app-pdf-viewer-img class="modal-presentation"
                            *ngIf="showViewer"
                            [config]="config"
                            [data]="presentation"
                            [mode]="mode"
                            [playerMode]="playerMode"
                            [resize$]="resize$"
                            (indexChanged$)="indexChanged($event)"
        >
        </app-pdf-viewer-img>
    </ng-container>
</app-resizable-element>

<!--[embeddedClass]="config.embeddedChatMode === CHAT_MODE.EMBEDDED ? 'presentation-embedded' : ''"-->
