import { Routes } from '@angular/router';
import { IdwLoginPageComponent } from './idwlogin-page.component';

export const commonDeclarations: any[] = [
  IdwLoginPageComponent,
];

export const commonImports: any[] = [
];

export const commonExports: any[] = [
  IdwLoginPageComponent,
];

export const routes: Routes = [
  {
    path: '',
    component: IdwLoginPageComponent
  }
];
