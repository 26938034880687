import { BannerHostDirective } from './../../directive/banner-host.directive';
import { CroppedTextPipe } from './../../pipes/croppedText.pipe';
import { LinkedStreamsModalComponent } from './../../components/streamdust-player/linked-streams-modal/linked-streams-modal.component';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    commonDeclarations,
    commonExports,
    commonImports
} from '@src/app/modules/shared/shared.common';
import {RouterModule} from '@angular/router';
import {FormsModule} from '@angular/forms';
import {InfoBoxComponent} from '@src/app/components/info-box/info-box.component';
import {InfinityScrollDirective} from '../../directive/infinity-scroll.directive';
import {EmptyResultsComponent} from '../../components/empty-results/empty-results.component';
import {PhotoCropperComponent} from '@src/app/components/photo-cropper/photo-cropper.component';
import {BottomModalComponent} from '@src/app/components/bottom-modal/bottom-modal.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {HeaderScrollHandlerDirective} from '@src/app/directive/headerScrollHandler/header-scroll-handler.directive';
import {PieChartComponent} from '@src/app/components/pie-chart/pie-chart.component';
import {BarChartComponent} from '@src/app/components/bar-chart/bar-chart.component';
import {ChartjsModule} from '@ctrl/ngx-chartjs';
import {FilterPipe} from '@src/app/pipes/filter.pipe';
import {NgxMaskModule} from 'ngx-mask';
import {LocalizationPipe} from '@src/app/pipes/localization.pipe';
import {PrintArrayPipe} from '@src/app/pipes/print-array.pipe';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import {TextFieldModule} from '@angular/cdk/text-field';
import {FileInputComponent} from '../../components/file-input/file-input.component';
import {SafeHtmlPipe} from '@src/app/pipes/safe-html.pipe';
import {IMaskModule} from 'angular-imask';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from '@danielmoncada/angular-datetime-picker';
import {MaterialModule} from '@src/app/modules/material/material.module';
import {DragAndDropComponent} from 'ui-elements';
import {DndDirective} from '@src/app/directive/dnd.directive';
import {SideNavComponent} from '@src/app/components/side-nav/side-nav.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {TableModule} from 'primeng/table';
import {FooterComponent} from '@src/app/components/footer/footer.component';
import {DefaultFormComponent} from '@src/app/components/default-form/default-form.component';
import {IbanPipe} from '@src/app/pipes/iban.pipe';
import {BorderedFormComponent} from '@src/app/components/bordered-form/bordered-form.component';
import {ToggleComponent} from '@src/app/components/toggle/toggle.component';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {BreadcrumbComponent} from '@src/app/components/breadcrumb/breadcrumb.component';
import {ChatComponent} from '@src/app/components/chat/chat.component';
import {MediaAccessModalComponent} from '@src/app/components/streamdust-player/media-access-modal/media-access-modal.component';
import {HeidelpayPaymentComponent} from '@src/app/components/payment-cards-list/heidelpay-payment/heidelpay-payment.component';
import {HeaderPopupComponent} from '@src/app/components/header-popup/header-popup.component';
import {NewPasswordComponent} from '@src/app/components/new-password/new-password.component';
import {StreamMultimediaUploadComponent} from '@src/app/pages/stream-page/components/stream-multimedia-upload/stream-multimedia-upload.component';
import {MediaListComponent} from '@src/app/components/media-list/media-list.component';
import {MediaCardComponent} from '@src/app/components/media-card/media-card.component';
import {MediaAgeRestrictionsModalComponent} from '@src/app/components/media-age-restrictions-modal/media-age-restrictions-modal.component';
import {GroupTableComponent} from '@src/app/components/group-table/group-table.component';
import {MediaListHeaderComponent} from '@src/app/components/media-list/media-list-header/media-list-header.component';
import {CleaveMaskDirective} from '@src/app/directive/cleave-mask/cleave-mask.directive';
import {ScrollToFirstErrorDirective} from '@src/app/directive/scroll-to-first-error.directive';
import {CarouselComponent} from '@src/app/components/carousel/carousel.component';
import {PaginatorComponent} from '@src/app/components/group-table/components/paginator/paginator.component';
import {MessageComponent} from '@src/app/components/chat/components/message/message.component';
import {PinnedMessageComponent} from '@src/app/components/chat/components/pinned-message/pinned-message.component';
import {ChangeForgottenPasswordComponent} from '@src/app/components/header-popup/change-forgotten-password/change-forgotten-password.component';
import {StreamdustPlayerComponent} from '@src/app/components/streamdust-player/streamdust-player.component';
import {DateUntilPipe} from '@src/app/pipes/date-until.pipe';
import {KeyToValuePipe} from '@src/app/pipes/key-to-value.pipe';
import {ItemSettingPipe} from '@src/app/pipes/item-setting';
import {PlanCardComponent} from '@src/app/components/tariff-plan-cards-list/plan-card/plan-card.component';
import {TariffPlanCardsListComponent} from '@src/app/components/tariff-plan-cards-list/tariff-plan-cards-list.component';
import {DateFormatPipe} from '@src/app/pipes/date-format.pipe';
import {DurationPipe} from '@src/app/pipes/duration.pipe';
import {PaymentCardsListComponent} from '@src/app/components/payment-cards-list/payment-cards-list.component';
import {PaymentCardComponent} from '@src/app/components/payment-cards-list/payment-card/payment-card.component';
import {InfoPanelComponent} from '@src/app/components/info-panel/info-panel.component';
import {DashboardHeaderComponent} from '@src/app/pages/dashboard-page/components/dashboard-header/dashboard-header.component';
import {SubscriptionPlanCardComponent} from '@src/app/components/tariff-plan-cards-list/subscription-plan-card/subscription-plan-card.component';
import {ViewerSurveyComponent} from '@src/app/components/viewer-survey/viewer-survey.component';
import {SchedulerEventsSubscriberComponent} from '@src/app/components/scheduler-events-subscriber/scheduler-events-subscriber.component';
import {ViewerSurveysComponent} from '@src/app/components/viewer-surveys/viewer-surveys.component';
import {AnswersComponent} from '@src/app/components/viewer-survey/components/answers/answers.component';
import {SimpleChartComponent} from '@src/app/components/simple-chart/simple-chart.component';
import {PickerModule} from '@ctrl/ngx-emoji-mart';
import {DonationsComponent} from '@src/app/components/donations/donations.component';
import {DonationOptionsComponent} from '@src/app/components/donations/components/donations-options/donation-options.component';
import {PaidDonationsComponent} from '@src/app/components/donations/components/paid-donations/paid-donations.component';
import {DateTimeFormatPipe} from '@src/app/pipes/date-time-format.pipe';
import {WebsitePipe} from '@src/app/pipes/website.pipe';
import {PeriodToggleComponent} from '@src/app/components/tariff-plan-cards-list/period-toggle/period-toggle.component';
import {MediaListsComponent} from '@src/app/components/media-lists/media-lists.component';
import {EmailPipe} from '@src/app/pipes/email.pipe';
import {PublicGroupComponent} from '@src/app/pages/public-group-page/public-group/public-group.component';
import {ImagePipe} from '@src/app/pipes/image.pipe';
import {CurrencyPipe} from '@src/app/pipes/currency.pipe';
import {StringCutPipe} from '@src/app/pipes/string-cut.pipe';
import {WhiteSpacePipe} from '@src/app/pipes/white-space.pipe';
import {StreamWorldMapComponent} from '@src/app/components/stream-world-map/stream-world-map.component';
import {BubbleMapComponent} from '@src/app/components/stream-world-map/bubble-map/bubble-map.component';
import {NumberWithSeparatorPipe} from '@src/app/pipes/number-with-separator.pipe';
import {MediaListSliderComponent} from '@src/app/components/media-list-slider/media-list-slider.component';
import {InvalidTokenComponent} from '@src/app/components/invalid-token/invalid-token.component';
import {ResizableModule} from 'angular-resizable-element';
import {AngularDraggableModule} from 'ngx-draggable-resize';
import {ResizableElementComponent} from '@src/app/components/resizable-element/resizable-element.component';
import {PublisherInfoComponent} from '@src/app/pages/public-media-page/components/publisher-info/publisher-info.component';
import {CalculateTicketComponent} from './../../components/calculate-ticket/calculate-ticket.component';
import {SubscriptionTariffsTableComponent} from '@src/app/pages/user-profile/components/subscription-manage/subscription-tariffs-table/subscription-tariffs-table.component';
import {DragScrollModule} from 'ngx-drag-scroll';
import {LocalizeDirective} from '@src/app/directive/localize.directive';
import {LinkedStreamsFilterComponent} from './../../pages/stream-page/components/stream-links/linked-streams-filter/linked-streams-filter.component';
import {SubscriptionTariffsTableENComponent} from '@src/app/pages/user-profile/components/subscription-manage/subscription-tariffs-table/subscription-tariffs-table-en.component';
import {SubscriptionTariffsTableDEComponent} from '@src/app/pages/user-profile/components/subscription-manage/subscription-tariffs-table/subscription-tariffs-table-de.component';
import {UiElementsModule} from 'ui-elements';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {PdfViewerComponent} from '@src/app/components/pdf-viewer/pdf-viewer.component';
import {PdfViewerModalComponent} from '@src/app/components/pdf-viewer-modal/pdf-viewer-modal.component';
import {PdfViewerImgComponent} from '@src/app/components/pdf-viewer-img/pdf-viewer-img.component';
import {AgmCoreModule} from '@agm/core';
import {AgmOverlays} from 'agm-overlays';
import {InfoLineComponent} from '@src/app/components/info-line/info-line.component';
import {TokenExpiredPageComponent} from '@src/app/components/token-expired-page/token-expired-page.component';
import {AgreementBoxComponent} from '@src/app/components/agreement-box/agreement-box.component';
import {PaymentModalComponent} from '@src/app/components/payment-modal/payment-modal.component';
import {GroupsModalComponent} from '@src/app/components/streamdust-player/groups-modal/groups-modal.component';
import {GroupsListComponent} from '@src/app/components/streamdust-player/groups-modal/components/groups-list/groups-list.component';
import {GroupInfoComponent} from '@src/app/components/streamdust-player/groups-modal/components/group-info/group-info.component';
import {GroupPaymentComponent} from '@src/app/components/streamdust-player/groups-modal/components/group-payment/group-payment.component';
import {SlotsModalComponent} from '@src/app/components/streamdust-player/slots-modal/slots-modal.component';
import {SlotsInfoComponent} from '@src/app/components/streamdust-player/slots-modal/components/slots-info/slots-info.component';
import {SlotsPaymentComponent} from '@src/app/components/streamdust-player/slots-modal/components/slots-payment/slots-payment.component';
import {AccessWindowComponent} from '@src/app/components/streamdust-player/access-window/access-window.component';
import {DurationConvertPipe} from '@src/app/pipes/duration-convert.pipe';
import {ShareModalComponent} from '@src/app/components/streamdust-player/share-modal/share-modal.component';
import {ProgressSpinnerComponent} from '@src/app/components/progress-spinner/progress-spinner.component';
import {MediaAccessButtonComponent} from '@src/app/components/streamdust-player/media-access-button/media-access-button.component';
import {AccessRestrictedPipe} from '@src/app/pipes/access-restricted.pipe';
import {IncludesPipe} from '@src/app/pipes/includes.pipe';
import {TransactionPipe} from '@src/app/pipes/transaction.pipe';
import {BorderedFormPreviewComponent} from '@src/app/components/bordered-form-preview/bordered-form-preview.component';
import {InfoModalComponent} from '@src/app/components/streamdust-player/info-modal/info-modal.component';
import {ChatWrapperComponent} from '@src/app/pages/chat-page/components/chat-wrapper/chat-wrapper.component';
import {PlayerComponent} from '@src/app/components/streamdust-player/player/player.component';
import {VideojsComponent} from '@src/app/components/streamdust-player/player/components/videojs/videojs.component';
import {PresentationListComponent} from '@src/app/components/presentation-list/presentation-list.component';

@NgModule({
    // entryComponents: [PaymentModalComponent],
    declarations: [
        ...commonDeclarations,
        ProgressSpinnerComponent,
        InfoBoxComponent,
        InfinityScrollDirective,
        EmptyResultsComponent,
        BottomModalComponent,
        PhotoCropperComponent,
        HeaderScrollHandlerDirective,
        PieChartComponent,
        BarChartComponent,
        FilterPipe,
        LocalizationPipe,
        PrintArrayPipe,
        FileInputComponent,
        SafeHtmlPipe,
        DndDirective,
        SideNavComponent,
        FooterComponent,
        DefaultFormComponent,
        IbanPipe,
        DateUntilPipe,
        KeyToValuePipe,
        ItemSettingPipe,
        BorderedFormComponent,
        BorderedFormPreviewComponent,
        ToggleComponent,
        BreadcrumbComponent,
        ChatComponent,
        MediaAccessModalComponent,
        MediaAccessButtonComponent,
        LinkedStreamsModalComponent,
        HeidelpayPaymentComponent,
        MediaAgeRestrictionsModalComponent,
        HeaderPopupComponent,
        NewPasswordComponent,
        ChangeForgottenPasswordComponent,
        StreamMultimediaUploadComponent,
        MediaListComponent,
        MediaCardComponent,
        GroupTableComponent,
        MediaListHeaderComponent,
        CleaveMaskDirective,
        ScrollToFirstErrorDirective,
        PaginatorComponent,
        MessageComponent,
        PinnedMessageComponent,
        CarouselComponent,
        StreamdustPlayerComponent,
        ChatWrapperComponent,
        PlanCardComponent,
        TariffPlanCardsListComponent,
        DateFormatPipe,
        LinkedStreamsFilterComponent,
        DurationPipe,
        PaymentCardsListComponent,
        PaymentCardComponent,
        InfoPanelComponent,
        DashboardHeaderComponent,
        SubscriptionPlanCardComponent,
        ViewerSurveyComponent,
        SchedulerEventsSubscriberComponent,
        ViewerSurveysComponent,
        AnswersComponent,
        SimpleChartComponent,
        DonationsComponent,
        DonationOptionsComponent,
        PaidDonationsComponent,
        DateTimeFormatPipe,
        WebsitePipe,
        PeriodToggleComponent,
        MediaListsComponent,
        EmailPipe,
        PublicGroupComponent,
        MediaListsComponent,
        ImagePipe,
        IncludesPipe,
        CurrencyPipe,
        CroppedTextPipe,
        StringCutPipe,
        WhiteSpacePipe,
        StreamWorldMapComponent,
        BubbleMapComponent,
        NumberWithSeparatorPipe,
        MediaListSliderComponent,
        InvalidTokenComponent,
        ResizableElementComponent,
        PublisherInfoComponent,
        CalculateTicketComponent,
        SubscriptionTariffsTableComponent,
        SubscriptionTariffsTableENComponent,
        SubscriptionTariffsTableDEComponent,
        LocalizeDirective,
        BannerHostDirective,
        PdfViewerComponent,
        PdfViewerModalComponent,
        PdfViewerImgComponent,
        InfoLineComponent,
        TokenExpiredPageComponent,
        AgreementBoxComponent,
        PaymentModalComponent,
        GroupsModalComponent,
        GroupsListComponent,
        GroupInfoComponent,
        GroupPaymentComponent,
        SlotsModalComponent,
        SlotsInfoComponent,
        SlotsPaymentComponent,
        AccessWindowComponent,
        DurationConvertPipe,
        ShareModalComponent,
        AccessRestrictedPipe,
        TransactionPipe,
        InfoModalComponent,
        PlayerComponent,
        VideojsComponent,
        PresentationListComponent,
        PdfViewerModalComponent,
    ],
  imports: [
    ...commonImports,
    FormsModule,
    CommonModule,
    RouterModule,
    ImageCropperModule,
    MatAutocompleteModule,
    ChartjsModule,
    NgxDatatableModule,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    MaterialModule,
    TableModule,
    BreadcrumbModule,
    NgxMaskModule.forChild(),
    PickerModule,
    ResizableModule,
    AngularDraggableModule,
    DragScrollModule,
    PdfViewerModule,
    AgmOverlays,
    AgmCoreModule,
    UiElementsModule,
    DragScrollModule
  ],
    exports: [
        ...commonExports,
        ProgressSpinnerComponent,
        MatAutocompleteModule,
        RouterModule,
        FormsModule,
        InfoBoxComponent,
        InfinityScrollDirective,
        EmptyResultsComponent,
        PhotoCropperComponent,
        BottomModalComponent,
        PhotoCropperComponent,
        ImageCropperModule,
        HeaderScrollHandlerDirective,
        PieChartComponent,
        BarChartComponent,
        FilterPipe,
        DateUntilPipe,
        NgxMaskModule,
        LocalizationPipe,
        PrintArrayPipe,
        NgxDatatableModule,
        MatFormFieldModule,
        MatInputModule,
        TextFieldModule,
        FileInputComponent,
        SafeHtmlPipe,
        IMaskModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        MaterialModule,
        DndDirective,
        SideNavComponent,
        TableModule,
        FooterComponent,
        DefaultFormComponent,
        IbanPipe,
        BorderedFormComponent,
        BorderedFormPreviewComponent,
        ToggleComponent,
        BreadcrumbModule,
        BreadcrumbComponent,
        ChatComponent,
        HeaderPopupComponent,
        NewPasswordComponent,
        StreamMultimediaUploadComponent,
        MediaListComponent,
        MediaCardComponent,
        GroupTableComponent,
        MediaListHeaderComponent,
        CleaveMaskDirective,
        ScrollToFirstErrorDirective,
        PaginatorComponent,
        MessageComponent,
        PinnedMessageComponent,
        CarouselComponent,
        StreamdustPlayerComponent,
        ChatWrapperComponent,
        KeyToValuePipe,
        ItemSettingPipe,
        PlanCardComponent,
        TariffPlanCardsListComponent,
        DateFormatPipe,
        LinkedStreamsFilterComponent,
        DurationPipe,
        PaymentCardsListComponent,
        InfoPanelComponent,
        DashboardHeaderComponent,
        SubscriptionPlanCardComponent,
        ViewerSurveyComponent,
        SchedulerEventsSubscriberComponent,
        ViewerSurveysComponent,
        AnswersComponent,
        SimpleChartComponent,
        DonationsComponent,
        DonationOptionsComponent,
        PaidDonationsComponent,
        DateTimeFormatPipe,
        WebsitePipe,
        PeriodToggleComponent,
        MediaListsComponent,
        EmailPipe,
        PublicGroupComponent,
        MediaListsComponent,
        ImagePipe,
        IncludesPipe,
        CurrencyPipe,
        CroppedTextPipe,
        StringCutPipe,
        WhiteSpacePipe,
        StreamWorldMapComponent,
        BubbleMapComponent,
        NumberWithSeparatorPipe,
        MediaListSliderComponent,
        InvalidTokenComponent,
        ResizableElementComponent,
        PublisherInfoComponent,
        CalculateTicketComponent,
        SubscriptionTariffsTableComponent,
        SubscriptionTariffsTableENComponent,
        SubscriptionTariffsTableDEComponent,
        UiElementsModule,
        BannerHostDirective,
        PdfViewerComponent,
        PdfViewerModalComponent,
        PdfViewerImgComponent,
        AgmOverlays,
        AgmCoreModule,
        InfoLineComponent,
        TokenExpiredPageComponent,
        AgreementBoxComponent,
        PaymentModalComponent,
        GroupsModalComponent,
        GroupsListComponent,
        GroupInfoComponent,
        GroupPaymentComponent,
        SlotsModalComponent,
        SlotsInfoComponent,
        SlotsPaymentComponent,
        AccessWindowComponent,
        DurationConvertPipe,
        ShareModalComponent,
        AccessRestrictedPipe,
        TransactionPipe,
        PlayerComponent,
        VideojsComponent,
        PresentationListComponent,
        PdfViewerModalComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule {
}
