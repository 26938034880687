<div class="presentation-list row">
  <div class="presentation-list__item col-sm-6 col-xl-4" *ngFor="let presentation of presentations">
    <div class="presentation-list__item-wrapper">
      <div class="presentation-list__item-img">
        <app-pdf-viewer-img [data]="presentation" [config]="{ isFullscreen: true }" [playerMode]="'VIEWER'">
        </app-pdf-viewer-img>
      </div>
      <div class="presentation-list__item-content">
        <h3 class="presentation-list__item-title">{{ presentation.title }}</h3>

        <div class="presentation-list__item-links">
          <a class="presentation-list__item-link" [href]="presentation.link" target="_blank">
            {{ presentation.link }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
