<div class="panel panel-md" appScrollToFirstError [forms]="[form, pictureForm]">
    <div class="panel-header">
        <h2><b>{{configPage.title | localize}}</b></h2>
        <p>{{configPage.subtitle | localize}}</p>
    </div>
    <div class="panel-body bg-white">
        <div class="section-panel">
            <div class="section-panel-header">
                <h4><b>{{configPage.titleCreate | localize}}</b></h4>
                <form *ngIf="form.get('startTime')?.value !== null" [formGroup]="activateForm">
                    <mat-slide-toggle [disabled]="(streamService.stream$ | async)?.media?.deactivated" [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>
                </form>
            </div>
        </div>
        <div class="row justify-content-between">

            <div class="col-md-9">
                <div class="section-panel">
                    <!--                    <div class="section-panel-header">-->
                    <!--                        <h4><b>{{configPage.titleCreate | localize}}</b></h4>-->
                    <!--                        <form *ngIf="form.get('startTime')?.value !== null" [formGroup]="activateForm">-->
                    <!--                            <mat-slide-toggle [formControlName]="'active'" class="video-activation-toggle"></mat-slide-toggle>-->
                    <!--                        </form>-->
                    <!--                    </div>-->
                    <app-info-panel *ngIf="(![STREAM_STATUS.SCHEDULED, STREAM_STATUS.FINISHED].includes((streamService?.streamStatus$ | async)?.stream) && this.payload.id && this.mediaType === MEDIA_TYPE.STREAM)" [infoPanel]="InfoPanelsConfig.streamStartedInfo"></app-info-panel>
                    <div class="section-panel-body">
                        <app-bordered-form
                                [defaultForm]="true"
                                [form]="form"
                                [fieldsConfig]="fieldsConfig"
                                [multimediaUrl]="photoUrl"
                                [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING) || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING) || (![STREAM_STATUS.SCHEDULED, STREAM_STATUS.FINISHED].includes((streamService?.streamStatus$ | async)?.stream) && this.payload.id && this.mediaType === MEDIA_TYPE.STREAM)"
                        ></app-bordered-form>
                    </div>
                </div>

                <div *ngIf="!photoUrl && form.get('position')?.value && overlaysType === 'course'" class="section-panel border-top">
                    <div class="section-panel-header">
                        <h4><b>{{'overlay.preview' | localize}}</b></h4>
                    </div>
                    <div class="section-panel-body">
                        <div class="overlay-preview">
                            <button
                                class="bottom-overlay"
                                [class.bottom-overlay]="form.get('position')?.value === 'BOTTOM'"
                                [class.right-overlay]="form.get('position')?.value === 'RIGHT'"
                                [class.left-overlay]="form.get('position')?.value === 'LEFT'"
                                type="button"
                                aria-disabled="false"
                            >
                                <div class="overlay">
                                    <div [ngStyle]="{'background-color': form.get('color')?.value}" class="overlay-box course-box">
                                        <p class="text" [ngClass]="form.get('color')?.value === 'BLACK' ? 'white' : 'black'">
                                            {{ form.get('textDescription')?.value }}
                                        </p>
                                        <button class="btn btn-primary btn-still">
                                            {{'course.button.stillHere' | localize}}
                                        </button>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="section-panel border-top">
                    <div class="section-panel-header">
                        <h4><b>{{'overlay.uploadImage' | localize}}</b></h4>
                        <p class="text-sm-left text-danger"
                           *ngIf="pictureDisabled">{{'overlay.selectPictureAlert' | localize}}</p>
                        <p class="text-sm-left text-danger"
                           *ngIf="incorrectPictureSize">{{'overlay.incorrectPictureSize' | localize}}</p>
                    </div>
                    <div class="section-panel-body"
                         [class.pb-0]="photoUrl"
                         [class.ml-auto]="form.get('position')?.value === 'RIGHT'"
                         #pictureContainer
                    >
                        <div
                            class="overlay-preview"
                            [class.preview-white]="form.get('color')?.value === 'WHITE'"
                            [class.preview-black]="form.get('color')?.value === 'BLACK'"
                        >
                            <app-bordered-form
                                [defaultForm]="true"
                                [form]="pictureForm"
                                [coursePreview]="{text: form.get('textDescription')?.value,
                                    color: form.get('color')?.value,
                                    position: form.get('position')?.value
                                }"
                                [fieldsConfig]="pictureConfig"
                                [disabled]="pictureDisabled || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) || (streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING) || (![STREAM_STATUS.SCHEDULED, STREAM_STATUS.FINISHED].includes((streamService?.streamStatus$ | async)?.stream) && this.payload.id && this.mediaType === MEDIA_TYPE.STREAM)"
                                (onFileUpload$)="uploadPreviewPhoto($event)"
                                (clearImage$)="clearImage()"
                                [multimediaUrl]="photoUrl"
                            ></app-bordered-form>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-12">
                <div *ngIf="!((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.FINISHED && !(streamService.stream$ | async)?.media?.hasRecording) && !(streamService.stream$ | async)?.media?.deactivated">
                    <div class="row row-padding-10 action-buttons">
                        <div class="col-auto">
                            <div class="form-group">
                                <button class="btn btn-secondary"
                                        (click)="cancel()">{{'common.button.close' | localize}}</button>
                            </div>
                        </div>
                        <div class="col-auto">
                            <div class="form-group">
                                <button class="btn btn-success"
                                        (click)="submit()">{{'common.button.apply' | localize}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ano-loader [isOpen]="(loadingService.loading | async)"></ano-loader>
</div>

<app-stream-overlay-location *ngIf="overlaysType === StreamMarketingToolsOverlaysTypes.OVERLAY && premiumPublisher && !((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                             [id]="payload.id"
                             class="stream-overlay-location"
                             [config]="overlayLocationConfig"
>
</app-stream-overlay-location>

