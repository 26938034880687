import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ComponentFactoryResolver, ViewContainerRef} from '@angular/core';
import {PDFDocumentProxy} from 'ng2-pdf-viewer';
import {DragScrollComponent} from 'ngx-drag-scroll';
import {$e} from 'codelyzer/angular/styles/chars';
import {PresentationService} from '@src/app/services/file/presentation.service';
import {takeUntil} from 'rxjs/operators';
import {getFileNameFromResponseContentDisposition} from '@src/app/utils/file-name-from-content-disposition.util';
import {saveAs} from 'file-saver/FileSaver';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {windowOpen} from '@src/app/utils/url.util';
import {CHAT_MODE} from '@src/app/components/chat/chat.component';
import {PLAYER_MODE} from '@src/app/components/streamdust-player/constants/playerMode';
import { environment } from '@src/environments/environment';
import {Observable} from 'rxjs';
import { PdfViewerModalComponent } from '../pdf-viewer-modal/pdf-viewer-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

export interface IPDFConfig {
    src?: string;
    currentPage?: number;
    totalPages?: number;
    originalSize?: boolean;
    showAll?: boolean;
    isDownload?: boolean;
    isLink?: boolean;
    isRemove?: boolean;
    isFullscreen?: boolean;
    presentation?: any;
}

interface ISlide {
    url: string;
}

export interface IPresentationData {
    fileId?: string;
    slides?: ISlide[];
    mediaId?: string;
    link?: string;
    viewerDownloadAllowed?: boolean;
}

@Component({
    selector: 'app-pdf-viewer-img',
    templateUrl: './pdf-viewer-img.component.html',
    styleUrls: ['./pdf-viewer-img.component.scss'],
    providers: [AutoDestroyService]
})
export class PdfViewerImgComponent implements OnInit, AfterViewInit {
    PLAYER_MODE = PLAYER_MODE;
    CHAT_MODE = CHAT_MODE;
    @Input() config: IPDFConfig;
    @Input() data: IPresentationData;
    @Input() mode: CHAT_MODE;
    @Input() playerMode: PLAYER_MODE;
    @Input() resize$: Observable<any>;
    private defaultConfig: IPDFConfig;
    @ViewChild('nav', {read: DragScrollComponent}) ds: DragScrollComponent;
    @ViewChild('presentationTmpl', {static: true, read: ViewContainerRef}) presentationTmpl: ViewContainerRef;

    @Output() remove$: EventEmitter<any> = new EventEmitter<any>();
    @Output() indexChanged$: EventEmitter<any> = new EventEmitter<any>();

    public environment = environment;
    public resizeStarted: boolean;

    constructor(
        public presentationService: PresentationService,
        public destroy$: AutoDestroyService,
        private dialog: MatDialog
    ) {
    }

    pdf: any;

    ngAfterViewInit() {
        setTimeout(() => {
            this.ds.moveTo(this.config.currentPage - 1);
        }, 0);
    }

    ngOnInit(): void {
        this.defaultConfig = {
            currentPage: 1,
            totalPages: 0,
            originalSize: false,
            showAll: false,
        };

        this.config = {...this.defaultConfig, ...this.config};

        this.afterLoadComplete();

        if (this.resize$) {
            this.resize$.pipe(takeUntil(this.destroy$))
                .subscribe(() => {
                    this.resizeStarted = true;
                });
        }
    }

    afterLoadComplete() {
        this.config.totalPages = this.data.slides.length;
    }

    nextPage() {
        if (this.config.currentPage === this.config.totalPages) {
            return;
        }
        // this.config.currentPage++;
        this.ds.moveRight();
    }

    prevPage() {
        if (this.config.currentPage === 1) {
            return;
        }
        // this.config.currentPage--;
        this.ds.moveLeft();
    }

    moveLeft() {
        this.ds.moveLeft();
    }

    moveRight() {
        this.ds.moveRight();
    }

    indexChanged($event: number) {
        if ($event !== null) {
            this.config.currentPage = $event + 1;
            this.indexChanged$.emit(this.config.currentPage);
        }
    }

    remove() {
        this.data = {};
        this.remove$.emit(this.data);
    }

    download() {
        this.presentationService.downloadPresentation(this.config.presentation.id).pipe(takeUntil(this.destroy$))
            .subscribe(res => {
                saveAs(
                    res.body,
                    getFileNameFromResponseContentDisposition(res)
                );
            }, () => {});
    }

    clickLink(link: string) {
        this.presentationService.clickLink(this.config.presentation.id).subscribe((res) => {
            windowOpen(link);
        });
    }

    public initialized(event: any): void {
        if (!this.resizeStarted) {
            return;
        }
        this.resizeStarted = false;
    }

    openFullscreen() {
        const dialogRef = this.dialog.open(PdfViewerModalComponent, {
            width: '100%',
            height: '100%',
            maxWidth: '100vw',
            maxHeight: '100vh',
            panelClass: 'fullscreen-dialog',
            hasBackdrop: false,
            disableClose: false,
            scrollStrategy: new NoopScrollStrategy(),
            data: {
                config: {
                    ...this.config,
                    isFullscreen: false,
                    presentation: {
                        fileId: this.data.fileId,
                        slides: this.data.slides,
                        mediaId: this.data.mediaId,
                        link: this.data.link,
                        viewerDownloadAllowed: this.data.viewerDownloadAllowed
                    }
                },
                mode: this.mode || CHAT_MODE.DEFAULT,
                playerMode: this.playerMode,
                isDialog: true,
            }
        });
    }
}
