import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from 'ui-elements';
import { tap } from 'rxjs/operators';
import { API_URLS_APP, UrlGenerator } from '@src/app/constants/api-urls.constant';
import { IResponse } from '@src/app/models/response.model';

@Injectable({
    providedIn: 'root'
})
export class IdwLoginService {
    private static readonly IDW_TOKEN_KEY = 'idwToken';
    private idwToken: string;

    constructor(private apiService: ApiService) {
        this.idwToken = IdwLoginService.getTokenFromStorage();
    }

    private static getTokenFromStorage(): string {
        return localStorage.getItem(IdwLoginService.IDW_TOKEN_KEY);
    }

    private static setTokenToStorage(token: string): void {
        localStorage.setItem(IdwLoginService.IDW_TOKEN_KEY, token);
    }

    public login(credentials: IIdwCredentials): Observable<IResponse<IIdwLoginResult>> {
        return this.apiService.post<IResponse<IIdwLoginResult>>(API_URLS_APP.IDW_LOGIN, credentials)
            .pipe(
                tap((response) => {
                    if (response?.results?.token) {
                        this.storeToken(response.results.token);
                    }
                })
            );
    }

    public logout(): Observable<IResponse<void>> {
        return this.apiService.get<IResponse<void>>(API_URLS_APP.IDW_LOGOUT)
            .pipe(
                tap(() => this.clearToken())
            );
    }

    public checkState(token: string): Observable<IResponse<boolean>> {
        return this.apiService.get<IResponse<boolean>>(
            UrlGenerator.generate(API_URLS_APP.IDW_STATE, { token })
        );
    }

    public isLoggedIn(): boolean {
        return !!this.idwToken;
    }

    public getToken(): string {
        return this.idwToken;
    }

    private storeToken(token: string): void {
        this.idwToken = token;
        IdwLoginService.setTokenToStorage(token);
    }

    private clearToken(): void {
        this.idwToken = null;
        localStorage.removeItem(IdwLoginService.IDW_TOKEN_KEY);
    }
}

export interface IIdwCredentials {
    email: string;
    password: string;
}

export interface IIdwLoginResult {
    token: string;
}
