<ano-table class="presentation-table" #tableComponent
           [tableConfig]="tableConfig"
           [defaultSortField]="'FILE_NAME'"
           (navigate$)="goToNoDataUrl($event)"
>
</ano-table>
<ng-template #statusTmpl let-data="rowData" class="ss-test">
    <div class="long-text">
        <app-toggle
                [disabled]="(streamService.stream$ | async)?.media?.deactivated || ((streamService.stream$ | async)?.media?.status?.stream === STREAM_STATUS.PENDING)"
                [value]="data.active"
                [config]="formsConfig"
                (toggleEmit)="onToggle($event, data)"
        ></app-toggle>
    </div>
</ng-template>
<ng-template #dateTmplCourses let-data="rowData">
    {{data.fileUploadDate | dateTimeFormat: 'DD MMM YYYY, HH:mm'}}
</ng-template>
<ng-template #actionsTmpl let-data="rowData">
    <div class="table-actions-list">
        <b class="text-primary">
            <i (click)="onEdit(data)"
                class="icon icon-edit cursor-pointer"></i>
        </b>
        <i (click)="onDelete(data)"
            class="icon icon-trash text-danger cursor-pointer"></i>
    </div>
</ng-template>

<ng-template #confirm let-dialogRef="dialogRef">
    <ano-modal [dialogRef]="dialogRef" [title]="'presentation.delete.title' | localize" [isConfirmation]="true">
        {{'presentation.delete.confirmText' | localize}}
    </ano-modal>
</ng-template>
