<div class="login-container">
  <div class="idw-header">
    <img src="assets/img/idw/idw.png" alt="IDW Logo" class="logo left-logo" />
    <div class="idw-title">
      <h2>{{ 'idwlogin.summit.title' | localize }}</h2>
    </div>
    <img src="assets/img/idw/digital-summit.png" alt="Digital Summit Logo" class="logo right-logo" />
  </div>
  <div class="login-box">
    <h1>{{ 'idwlogin.title' | localize }}</h1>

    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="email">{{ 'idwlogin.email' | localize }}</label>
        <input
          type="email"
          id="email"
          formControlName="email"
          [class.invalid]="loginForm.get('email').invalid && loginForm.get('email').touched"
        />
        <div class="error-message" *ngIf="loginForm.get('email').invalid && loginForm.get('email').touched">
          {{ 'idwlogin.email.error' | localize }}
        </div>
      </div>

      <div class="form-group">
        <label for="password">{{ 'idwlogin.password' | localize }}</label>
        <input
          type="password"
          id="password"
          formControlName="password"
          [class.invalid]="loginForm.get('password').invalid && loginForm.get('password').touched"
        />
        <div class="error-message" *ngIf="loginForm.get('password').invalid && loginForm.get('password').touched">
          {{ 'idwlogin.password.error' | localize }}
        </div>
        <div class="error-message" *ngIf="errorMessage">
          {{ errorMessage }}
        </div>
      </div>

      <button type="submit" [disabled]="isLoading" class="login-button">
        {{ isLoading ? ('idwlogin.loading' | localize) : ('idwlogin.login' | localize) }}
      </button>
    </form>
  </div>
</div>
