import {AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AutoDestroyService} from '@src/app/services/auto-destroy-service/auto-destroy.service';
import {ISideNavConfig} from '@src/app/components/side-nav/side-nav.component';
import {RouterEventHandlerService} from '@src/app/services/router-event-handler/router-event-handler.service';
import {SideNavConfigModel} from '@src/app/models/side-nav-config.model';
import {IUserProfile, ProfileService} from '@src/app/pages/user-profile/components/profile/services/profile.service';
import {UserService} from '@src/app/services/user/user.service';
import {AuthService} from '@src/app/services/auth/auth.service';
import {BackdropService} from '@src/app/services/backdrop/backdrop.service';
import {IInfoPanel, InfoPanelsConfig} from '@src/app/components/info-panel/info-panel.component';
import {USER_TYPE} from '@src/app/constants/user-type.constant';
import {StreamService} from '@src/app/services/stream-metadata/stream.service';
import {RECORD_STATUS, STREAM_STATUS, STOP_REASON} from './components/streamdust-player/constants/status';
import {fromEvent} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import {scrollToTop} from '@src/app/utils/scroll.util';
import {IPublicMediaInfo} from '@src/app/models/core.model';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {SubAccountsService} from '@src/app/services/sub-accounts/sub-accounts.service';
import {PublicMediaPageComponent} from '@src/app/pages/public-media-page/public-media-page.component';
import {EventSiteState} from '@src/app/state/event-site-state';
import {HeaderService} from '@src/app/services/header/header.service';
import {ScriptService} from './services/script/script.service';
import {PlayerState} from '@src/app/state/player-state';
import {PaymentService} from '@src/app/services/payment/payment.service';
import {GenerateUtil} from '@src/app/utils/generate.util';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MEDIA_TYPE} from '@src/app/components/streamdust-player/constants/mediaType';
import { IdwLoginService } from './services/idwlogin/idwlogin.service';

declare const Cookiebot: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [AutoDestroyService, UserService, ProfileService, SubAccountsService, ScriptService]
})
export class AppComponent implements OnInit, AfterViewInit {
    @ViewChild('publicMediaPageComponent', {static: true}) public publicMediaPageComponent: PublicMediaPageComponent;
    @ViewChild('bandwidthDialog', {static: false}) bandwidthDialog: TemplateRef<any>;
    public InfoPanelsConfig = InfoPanelsConfig;
    public STREAM_STATUS = STREAM_STATUS;
    public RECORD_STATUS = RECORD_STATUS;
    public STOP_REASON = STOP_REASON;
    public navConfig: ISideNavConfig = SideNavConfigModel.getLeftMenuConfig();
    public eventSiteLoaded: boolean;
    public eventSite: IPublicMediaInfo;
    public eventSiteNavigate: boolean;
    private initFinished: boolean;
    private loaderPic = true;
    public headerImage: string;
    private instanceId = GenerateUtil.uuidv4();
    public isTokenExpiredPage;
    private bandwidthStreamId = null;
    private dialogRef: MatDialogRef<any> | null = null;

    public get sideNavConfig(): ISideNavConfig {
        return this.routerEventHandlerService.activatedRouteData.sideNavConfig || null;
    }

    public get rightMenu(): boolean {
        return this.routerEventHandlerService.rightMenu;
    }

    public get withMenu(): boolean {
        return !this.routerEventHandlerService.activatedRouteData.withoutMenu;
    }

    public get embedded(): boolean {
        return this.routerEventHandlerService.activatedRouteData.embedded;
    }

    public get hideHeaderImage(): boolean {
        return this.routerEventHandlerService.activatedRouteData.hideHeaderImage;
    }

    public get customHeaderImage(): boolean {
        return this.routerEventHandlerService.activatedRouteData.customHeaderImage;
    }

    public get hideSubAccountPanel(): boolean {
        return this.routerEventHandlerService.activatedRouteData.hideSubAccountPanel;
    }

    private _embedded: boolean;

    infoPanel: IInfoPanel = InfoPanelsConfig.publisherUnapprovedInfo;
    userProfile: IUserProfile;
    USER_TYPE = USER_TYPE;

    private readonly cbid = 'ceec2797-325d-49aa-a307-534056df5cb8';

    public playerState = false;
    public isIdwLogin: boolean;

    constructor(
        public routerEventHandlerService: RouterEventHandlerService,
        public authService: AuthService,
        public userService: UserService,
        public backdropService: BackdropService,
        public streamService: StreamService,
        private destroy$: AutoDestroyService,
        private router: Router,
        private subAccountsService: SubAccountsService,
        private eventSiteState: EventSiteState,
        private scriptService: ScriptService,
        public playerStateService: PlayerState,
        private paymentService: PaymentService,
        private activatedRoute: ActivatedRoute,
        private cd: ChangeDetectorRef,
        public headerService: HeaderService,
        public dialog: MatDialog,
        public idwLoginService: IdwLoginService
    ) {
    }


    public ngOnInit(): void {
        // this.scriptService
        //     // one or more arguments
        //     .load('CookieDeclaration')
        //     .then();
        this.checkEmbedded();

        this.isIdwLogin = this.idwLoginService.isLoggedIn();

        this.playerStateService.state$.pipe(takeUntil(this.destroy$)).subscribe((res) => {
            this.playerState = res;
            this.cd.detectChanges();
        });

        this.streamService.getVideo().subscribe((res) => {
            this.eventSiteLoaded = true;
            this.eventSite = res;
            if (!this.eventSite) {
                this.runCookieBot();
                return;
            }
            this.eventSiteState.setIsEventSite(true);
            this.router.events
                .pipe(filter(event => event instanceof NavigationEnd))
                .subscribe((event: NavigationEnd) => {
                    this.eventSiteNavigate = event.url.split('/').length !== 2;
                });
        }, () => this.eventSiteLoaded = true);
        this.authService.isAuthorized$.pipe(takeUntil(this.destroy$)).subscribe(authorized => {
            this.userService.publisherHeaderPictureURL$.pipe(takeUntil(this.destroy$)).subscribe(res => {
                this.headerImage = res;
            });
            if (authorized) {
                this.userService.refreshUserProfile();
            }
        });


        if (this.authService.isSessionExpired()) {
            this.authService.logout();
        }

        this.userService.userProfile$.subscribe((userProfile) => {
            if (userProfile) {
                this.userProfile = userProfile;
                if (this.userProfile.accountType === USER_TYPE.SUB_ACCOUNT && !this.initFinished) {
                    this.subAccountsService.logoutIfSubaccountOpenedInNewTab()
                        .pipe(takeUntil(this.destroy$))
                        .subscribe((res) => {
                            if (!res) {
                                return;
                            }
                            this.authService.logout(false);
                            this.authService.storeToken(res.authToken);
                        });
                }
                this.initFinished = true;
            }
        });
        fromEvent(document, 'DOMContentLoaded').pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.refreshBannerMinHeight();
                scrollToTop();
            });
        fromEvent(window, 'resize').pipe(takeUntil(this.destroy$))
            .subscribe(() => {
                this.refreshBannerMinHeight();
            });

        this.userService.reloadLand.subscribe(res => {
            this.eventSiteLoaded = false;
            setTimeout(() => {
                this.eventSiteLoaded = true;
            }, 1000);
        });

        this.authService.logoutEmitter.pipe(takeUntil(this.destroy$)).subscribe(res => {
            this.userService.headerPictureLoaded = false;
        });
        this.headerService.isTokenExpiredPage.pipe(takeUntil(this.destroy$)).subscribe(res => {
            this.isTokenExpiredPage = res;
            this.cd.detectChanges();
        });

        this.streamService.bandwidthWarning$
            .pipe(takeUntil(this.destroy$))
            .subscribe((result) => {
                if (result?.streamId && this.userProfile?.ownerId === result?.ownerId) {
                  this.bandwidthStreamId = result?.streamId;
                  this.showBandwidthDialog();
                }
            });
    }


    ngAfterViewInit(): void {
        this.refreshBannerMinHeight();
    }


    public showBandwidthDialog(): void {
        if (!this.dialogRef) {
            this.dialogRef = this.dialog.open(this.bandwidthDialog);

            this.dialogRef.afterClosed()
                .pipe(takeUntil(this.destroy$))
                .subscribe((confirm: boolean) => {
                    if (confirm && this.bandwidthStreamId) {
                        this.router.navigate(['/bandwidth/upgrade/4/WEEK']);
                    }
                    this.bandwidthStreamId = null;
                    this.dialogRef = null;
                });
        }
    }

    private refreshBannerMinHeight(): void {
        const width = window.innerWidth;
        const banner = document.getElementById('publisher-banner');
        if (banner) {
            banner.style.height = (width * 200 / 1366) + 'px';
        }
    }

    private checkEmbedded(): void {
        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((event: any) => {
                this._embedded = event.url.includes('/embedded/');
            });
    }

    private runCookieBot(): void {
        if (this._embedded) {
            return;
        }

        const script = document.createElement('script');
        script.id = 'Cookiebot';
        script.type = 'text/javascript';
        script.src = `https://consent.cookiebot.com/uc.js`;
        script.dataset.cbid = this.cbid;
        script.dataset.blockingmode = 'manual';
        document.head.appendChild(script);
    }
}
